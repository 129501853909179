import React  from "react";

import {
    Filter,
    TextInput,
    DateTimeInput,
    DateInput,
} from 'react-admin';
// import {  DateTimeInput } from 'react-admin-date-inputs';


export const FilterFinal = (props) => (
    <Filter {...props}>
        <TextInput label="Order Number" source="amazonOrderId"/>
        <TextInput label="Sales Channel" source="salesChannel"/>
        <TextInput label="Order Status" source="orderStatus"/>
        <TextInput label="Buyer Name" source="buyerName"/>
        {/*<DateInput label="Date >=" parse source="purchaseDate_gt"/>*/}
        {/*<DateInput label="Date <=" source="purchaseDate_lt"/>*/}
        {/*<TextInput label="ASIN" source="asin"/>*/}
    </Filter>
);

import uuid from 'uuid';
import {getCookie, removeCookie, setCookie} from "../../util/cookies";

const CART_TOKEN_COOKIE = 'cart-session-id';
const USER_TOKEN_COOKIE = 'user-token';

export const getFinalCartToken = (config,cartId) => {
  try{
    return  cartId ? cartId : session(config).Cart.id();
  }
  catch (exception) {
    if (exception instanceof CartDoesNotExist) {
      return null;
    }
    throw exception;
  }
};

export const setCartToken = (config, cartTokenCookie,newSessionId) => {
  const params = {
    key: cartTokenCookie,
    value: newSessionId,
    expiration: config.cartCookieExpiration || 604800000 // 7 days
  };

  if (config.cookieDomain) {
    params.domain = config.cookieDomain;
  }
  setCookie(params);
};

export class CartDoesNotExist {
  message = 'Cart has not been initialized';
}

const session = config => {
  const cartTokenCookie = config.cartTokenCookie || CART_TOKEN_COOKIE;

  return ({
    Cart: {
      id: () => {
        const id = getCookie(cartTokenCookie, config.req);

        if (typeof id === 'undefined') {
          throw new CartDoesNotExist();
        }

        return id;
      },

      setId: (newSessionId) => {
        setCartToken(config, cartTokenCookie, newSessionId);
        return newSessionId;
      },

      generateId: () => {
        const newSessionId = uuid();
        setCartToken(config, cartTokenCookie, newSessionId);
        return newSessionId;
      },

      remove: () => {
        const params = {};

        if (config.cookieDomain) {
          params.domain = config.cookieDomain;
        }

        return removeCookie(cartTokenCookie, params);
      }
    },
    User: {
      token: () => {
        return getCookie(USER_TOKEN_COOKIE, config.req);
      },
      set: (token, exp) => {
        const params = {
          key: USER_TOKEN_COOKIE,
          value: token,
          expiration: exp || config.userCookieExpiration || 2592000000 // 1 month
        };

        if (config.cookieDomain) {
          params.domain = config.cookieDomain;
        }

        setCookie(params);
      },
      remove: () => {
        const params = {};

        if (config.cookieDomain) {
          params.domain = config.cookieDomain;
        }

        return removeCookie(USER_TOKEN_COOKIE, params);
      }
    }
  });
};

export default session;

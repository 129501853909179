export default config => ({slug}) => {
  // const headers = merge(
  //     channelParams(config),
  //     localeParams(config),
  //     authParams(config),
  // );
  return {
    query: () => {
      return {
        type: 'getOne',
        resource: 'products',
        payload: {
          url: `${config.baseUrl}/products/by-slug/${slug}`,
          id: slug
        },
      };
    },
    response: (response) => {
      return response;
    }
  };
};

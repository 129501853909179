export const amazonShipmentPlan = {
    name: 'amazoninboundshipments',
    label: 'Shipment Plans',
};
export const amazonShipmentPlanItem = {
    name:   'amazoninboundshipmentplanitems',
    label:   'Shipment Items'
};
export const amazonShipmentPlanTransports = {
    name: 'amazoninboundshipmenttransports',
    label: 'Transports',
};
export const amazonOrder = {
    name: 'amazonorders',
    label: 'Orders',
};

export const invoice = {
    name: 'invoices',
    label: 'Invoices',
};

export const amazonOrderItem = {
    name: 'amazonorderitems',
    label: 'Amazon Order Item',
};


export const taxReport = {
    name: 'taxreports',
    label: 'Tax Report',
};

import {getFinalCartToken} from "../session";

export default config => ({coupon}) => {
    return {
        query: () => {
            const cartToken = getFinalCartToken(config);
            return cartToken ? {
                type: 'update',
                resource: 'carts',
                payload: {
                    url: `${config.baseUrl}/carts/${cartToken}/coupon`,
                    data: {coupon}
                },
            } : null;
        },
        response: (response) => {
            return response;
        }
    };
};

import {getFinalCartToken} from '../session';

export default config => () => {
    return {
        query: () => {
            const cartToken = getFinalCartToken(config);
            return cartToken ? {
                type: 'getOne',
                resource: 'carts',
                payload: {
                    url: `${config.baseUrl}/checkout/${cartToken}/shipping`,
                    id: cartToken
                },
            }
            : null;
        },
        response: (response) => {
            if (response.shipments[0].length === 0) {
                return [];
            }
            const shippingMethods = response.shipments[0].methods;
            return Object.keys(shippingMethods).map(key => (shippingMethods[key]));
        }
    };
};

import {ListFinal} from "./List";
import {ShowFinal} from "./Show";
import {EditFinal,CreateFinal} from "./Edit";
import {invoice} from "../../resources";

export default {
    name:   invoice.name,
    list:   ListFinal,
    edit:   EditFinal,
    create:   CreateFinal,
    show:   ShowFinal,
    options:   { label: invoice.label },
};

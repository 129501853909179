import {getFinalCartToken} from "../session";

export default config => ({cartId} = {}) => {
  return {
    query: () => {
      const cartToken = getFinalCartToken(config,cartId);
      return cartToken ? {
        type: 'getOne',
        resource: 'carts',
        payload: {
          url: `${config.baseUrl}/carts/${cartToken}`,
          id: cartToken
        },
      } : null;
    },
    response: (response) => {
      return response;
    }
  };
};

import session, {getFinalCartToken} from "../session";

export default config => ({productCode, variantCode, offerId, quantity, ...rest}) => {
  return {
    query: () => {
      const oldCartToken = getFinalCartToken(config);
      const cartToken  = oldCartToken ? oldCartToken : 'new';
      return cartToken ? {
        type: 'create',
        resource: 'carts',
        payload: {
          url: `${config.baseUrl}/carts/${cartToken}/items`,
          data: {productCode, variantCode, quantity, offerId, ...rest}
          // data: {offerId: '1', quantity: 1}
        },
      } : null;
    },
    response: (response) => {
      session(config).Cart.setId(response.data.tokenValue);
      return response;
    }
  };
};

import React  from "react";

import { Show,
    TextField,
    TabbedShowLayout,
    Tab, ReferenceArrayField, Datagrid
} from 'react-admin';
import { withStyles, createStyles } from '@material-ui/core/styles';
// import {useResourceOptions} from "hooks";
import {Table, TableHead, TableBody,TableRow, TableCell} from "@material-ui/core";
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';

const Title = ({ record }) => {
    return <span>{record ? `"${record.amazonOrderId}"` : ''}</span>;
};

const Styles = function (theme) {
    return createStyles({

    });
};

export const ShowPlain = (props) =>
{
    return (
        <Show title={<Title/>} {...props}>
            <TabbedShowLayout>
                <Tab label="invoice">
                    <InvoiceItemTable {...props}/>
                    <InvoiceOrderDetails {...props}/>
                </Tab>
                {/*<Tab label="summary" path="summary">*/}
                {/*    <TextField source="asin" />*/}
                {/*    <TextField source="sku" />*/}
                {/*</Tab>*/}

            </TabbedShowLayout>
        </Show>
    );
};

const InvoiceItemTable = (props) =>{
    // const { readableFields, operations } = useResourceOptions(props.resource);
    const { record } = props;

    
    return (
        <ReferenceArrayField label="Products" reference="products" source="product_ids">
             <Datagrid>
                 <FieldGuesser source="id" />
                 <FieldGuesser source="description" />
             </Datagrid>
         </ReferenceArrayField>

    )};

const InvoiceOrderDetails = (props) =>{
    // const { readableFields, operations } = useResourceOptions(props.resource);
    const { record } = props;

    const orderInfos = [
        'orderStatus',
        'fulfillmentChannel',
        'amazonOrderId',
        'sellerOrderId',
        'purchaseDate',
        'marketplaceId',
        'buyerName',
        'buyerEmail',
        'billingAddressCountryCode',
        'orderTotalCurrencyCode',
        'buyTaxInfo',
        'warehouseCenterId',
        'shippingPrice',
        'vatNumber',
        'Rate VAT',
        'Total VAT',
        'Shipping Tax',
        'GW Price',
        'GW Tax',
        'Item Tax',
        'ShippingDiscount',
        'PromoDiscount',
    ];

    return (
        <Table size="small" aria-label="Order Details">
            <TableHead>
                <TableRow>
                    <TableCell>Information</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    orderInfos.map(columnKey => {
                        return (
                            <TableRow key={columnKey}>
                                <TableCell component="th" scope="row">{columnKey}</TableCell>
                                <TableCell>{record[columnKey]}</TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>

    )};

export const ShowFinal = withStyles(Styles)(ShowPlain);

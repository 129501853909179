export const IntrospectReducer =  (previousState = 0, { type, payload }) => {
    // console.log(type);
    if (type === 'BITCOIN_RATE_RECEIVED') {
        return payload.rate;
    }
    // if (type === 'RA/SET_SIDEBAR_VISIBILITY') {
    //     return Math.random();
    // }
    return previousState;
}

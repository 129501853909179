import React from "react";
import {
    NumberField,
    ReferenceField
} from 'react-admin';

export const Currency = ({source, ...props}) =>{
    const {record} = props;
    return (
        <ReferenceField source="store" reference="stores" link={false} {...props}>
            {
                <CurrencyFinal originalRecord={record} originalSource={source}/>
            }
        </ReferenceField>
    )
};

const CurrencyFinal = ({record,...props}) => {
    var {originalRecord,originalSource,locales} = props;
    if(!locales){
        if(record.currency === 'GBP') locales = 'en-GB';
        else locales = 'fr-FR';
    }
    return (
        <NumberField record={originalRecord} source={originalSource} locales={locales}
                     options={{style: 'currency', currency: record.currency || 'EUR'}} />
    )
};


export const CurrencyField = ({record, source, currency}) => {
    return (
        <NumberField record={record} source={source} options={{style: 'currency', currency: currency}} />
    )
};

export const roundValue = (value, round = 2) => value.toFixed(round);

import {syliusApiClient} from "../../syliusApiClient";

export default config => () => {
  return {
    query: () => {
      const token = syliusApiClient().Session.User.token();
      return token ?  {
        type: 'getList',
        resource: 'tickets',
        payload: {
          url: `${config.baseUrl}/tickets`,
          pagination: {},
          sort: {},
        },
      } : null;
    },
    response: (response) => {
      return response;
    }
  };
};

import mapValues from 'lodash.mapvalues';

import HomePage from './Page/Home';
import PageByCode from './Page/PageByCode';

import currentChannel from './Channel/Current';
import cartPickup from './Cart/Pickup';
import cartSummary from './Cart/Summary';
import cartAddItem from './Cart/AddItem';
import cartRemoveItem from './Cart/RemoveItem';
import cartChangeItemQuantity from './Cart/ChangeItemQuantity';
import addCoupon from './Cart/AddCoupon';
import removeCoupon from './Cart/RemoveCoupon';

import checkoutAddress from './Checkout/Address';
import checkoutAvailableShippingMethods from './Checkout/AvailableShippingMethods';
import checkoutAvailablePaymentMethods from './Checkout/AvailablePaymentMethods';
import checkoutChoosePaymentMethod from './Checkout/ChoosePaymentMethod';
import checkoutChooseShippingMethod from './Checkout/ChooseShippingMethod';
import checkoutComplete from './Checkout/Complete';
import SubmitCreditCardPayment from './Checkout/SubmitCreditCardPayment';

import productBySlug from './Product/BySlug';
import productById from './Product/ById';
import CreateProductReview from './Product/CreateProductReview';
import LatestProducts from './Product/LatestProducts';
import Search from './Search/Search';
import ScanSearch from './Search/ScanSearch';

import taxonAll from './Taxon/All';
import taxonProductsByTaxonSlug from './Taxon/ProductsByTaxonSlug';

import userLogin from './User/Login';
import userMe from './User/Me';
import userRegister from './User/Register';
import userPasswordReset from './User/PasswordReset';
import PasswordChange from './User/PasswordChange';
import userRequestPasswordReset from './User/RequestPasswordReset';
import TicketList from './Ticket/TicketList';

import OrdersList from './Order/OrdersList';
import OrderDetail from './Order/OrderDetail';
import OrderPaymentToken from './Order/OrderPaymentToken';

import AddMessage from './Ticket/AddMessage';
import UpdateProfile from './User/UpdateProfile';

import requestConfig from './requestConfig';
import session from './session';

import TicketDetail from './Ticket/TicketDetail';
import TicketMessagesById from './Ticket/TicketMessagesById';
import CreateTicket from './Ticket/CreateTicket';

import CreateAddress from './Address/CreateAddress';
import EditAddress from './Address/EditAddress';
import DeleteAddress from './Address/DeleteAddress';

export {requestConfig};

export default ({customEndpoints = {}, ...config}) => ({
  Session: session(config),
  channel: {
    currentChannel: currentChannel(config)
  },
  page: {
    home: HomePage(config),
    pageByCode: PageByCode(config)
  },
  cart: {
    pickup: cartPickup(config),
    summary: cartSummary(config),
    addItem: cartAddItem(config),
    removeItem: cartRemoveItem(config),
    changeItemQuantity: cartChangeItemQuantity(config),
    addCoupon: addCoupon(config),
    removeCoupon: removeCoupon(config),
  },
  checkout: {
    address: checkoutAddress(config),
    chooseShippingMethod: checkoutChooseShippingMethod(config),
    choosePaymentMethod: checkoutChoosePaymentMethod(config),
    availableShippingMethods: checkoutAvailableShippingMethods(config),
    availablePaymentMethods: checkoutAvailablePaymentMethods(config),
    complete: checkoutComplete(config),
    submitCreditCardPayment: SubmitCreditCardPayment(config),
  },
  taxon: {
    all: taxonAll(config),
    productsByTaxonSlug: taxonProductsByTaxonSlug(config),
  },
  product: {
    bySlug: productBySlug(config),
    byId: productById(config),
    latestProducts: LatestProducts(config),
    createProductReview: CreateProductReview(config),
  },
  search: {
    search: Search(config),
    scanSearch: ScanSearch(config),
  },
  ticket: {
    addMessage: AddMessage(config),
    ticketList: TicketList(config),
    ticketDetail: TicketDetail(config),
    ticketMessagesById: TicketMessagesById(config),
    createTicket: CreateTicket(config),
  },
  order: {
    ordersList: OrdersList(config),
    orderDetail: OrderDetail(config),
    orderPaymentToken: OrderPaymentToken(config),
  },
  address: {
    createAddress: CreateAddress(config),
    editAddress: EditAddress(config),
    deleteAddress: DeleteAddress(config),
  },
  user: {
    login: userLogin(config),
    me: userMe(config),
    updateProfile: UpdateProfile(config),
    register: userRegister(config),
    requestPasswordReset: userRequestPasswordReset(config),
    passwordReset: userPasswordReset(config),
    passwordChange: PasswordChange(config),
  },
  custom: mapValues(customEndpoints, (endpoint) => endpoint(config))
});

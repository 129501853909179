import React  from "react";

import {
    Filter,
    DateInput,
} from 'react-admin';


export const FilterFinal = (props) => (
    <Filter {...props}>
        <DateInput label="From" source="startDate"/>
        <DateInput label="To" source="endDate"/>
    </Filter>
);

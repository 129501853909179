import React, { Fragment }   from "react";

import { List,
    Datagrid,
    ShowButton,
} from 'react-admin';
import IntrospectQuery from 'dataProvider/IntrospectQuery';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import {useResourceOptions} from "hooks";
import {DefaultListProps} from "../Common/DefaultListProps";

export const ListPlain = ({...props }) => {

    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    // console.log(readableFields);
    return (
        <Fragment>
            <List  filters={<FilterFinal/>} {...DefaultListProps({operations})}  {...props}>
                <Datagrid>

                    {/*{readableFields.store &&*/}
                    {/*<ReferenceField label="Store" source="store" reference="stores" link={false}>*/}
                    {/*    <FieldGuesser label={'Store'} source={'name'}/>*/}
                    {/*</ReferenceField>*/}
                    {/*}*/}
                    {readableFields.amazonOrderId && <FieldGuesser label={'Order Number'} source={'amazonOrderId'} sortable={false}/>}
                    {readableFields.purchaseDate && <FieldGuesser label={'Order Date'} source={'purchaseDate'}/>}

                    {readableFields.salesChannel && <FieldGuesser label={'Sales Channel'} source={'salesChannel'} sortable={false}/>}
                    {readableFields.fulfillmentChannel && <FieldGuesser label={'Fulfillment Method'} source={'fulfillmentChannel'}/>}


                    {readableFields.orderStatus && <FieldGuesser label={'Order Status'} source={'orderStatus'} sortable={false}/>}
                    {readableFields.buyerName && <FieldGuesser label={'Buyer Name'} source={'buyerName'} sortable={false}/>}

                    {readableFields.orderTotalAmount && <TotalAmount source={'orderTotalAmount'} label={'Total Order'} sortable={true}/>}

                    {/*{readableFields.warehouseCenterId && <FieldGuesser label={'warehouseCenterId'} source={'warehouseCenterId'}/>}*/}
                    {/*{readableFields.vatNumber && <FieldGuesser label={'vatNumber'} source={'vatNumber'}/>}*/}

                    {/*{readableFields.estimatedBoxContentsFee && <FieldGuesser label={'Estimated Box Contents Fee'} source={'estimatedBoxContentsFee'}/>}*/}
                    {/*{readableFields.transportStatus && <FieldGuesser label={'Transport Status'} source={'transportStatus'}/>}*/}
                    {/*{readableFields.planCreatedOn && <FieldGuesser label={'Plan Created On'} source={'planCreatedOn'}/>}*/}
                    {operations.view && <InvoiceShowButton/>}
                </Datagrid>
            </List>
        </Fragment>
    );
};

const InvoiceShowButton = (props) => {
    const {record} = props;
    return (
        <>
            {record.invoices.length > 0 && <ShowButton {...props}/>}
            </>
    )
};

const TotalAmount = ({ record }) => {
    return <span>{record && record.orderTotalAmount && record.orderTotalCurrencyCode ? `${record.orderTotalAmount} ${record.orderTotalCurrencyCode}` : ''}</span>;
};

export const ListFinal = props => (
    <IntrospectQuery component={ListPlain} {...props} />
);

import {
  CREATE,
  DELETE,
  // DELETE_MANY,
  GET_LIST,
  // GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
} from 'react-admin';
import isPlainObject from 'lodash.isplainobject';
// import { fetchHydra  } from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import { isEqual } from "lodash";
import {DifferenceBetweenTwoObjects} from "../util/DifferenceBetweenObjects";

// import {dataProvider} from "./fetchHeader";



var gomcoResourceMapping = {
  'activeproducts': 'products',
};
const gomcoResource = originalResource => {
  if(gomcoResourceMapping.hasOwnProperty(originalResource)) return gomcoResourceMapping[originalResource];
  return  originalResource;
};

export class IdBasedReactAdminDocument {
  constructor(obj) {
    Object.assign(this, obj, {
      originId: obj.id,
      id: obj['id'],
    });
  }

  /**
   * @return {string}
   */
  toString() {
    return `[object ${this.id}]`;
  }
}

export class SyliusReactAdminDocument {
  constructor(obj) {
    Object.assign(this, obj, {
      originId: obj.code,
      id: obj['code'],
    });
  }

  /**
   * @return {string}
   */
  toString() {
    return `[object ${this.id}]`;
  }
}

export class ReactAdminDocument {
  constructor(obj) {
    Object.assign(this, obj, {
      originId: obj.id,
      id: obj['@id'],
    });
  }

  /**
   * @return {string}
   */
  toString() {
    return `[object ${this.id}]`;
  }
}

/**
 * Local cache containing embedded documents.
 * It will be used to prevent useless extra HTTP query if the relation is displayed.
 *
 * @type {Map}
 */
export const reactAdminDocumentsCache = new Map();

/**
 * Transforms a JSON-LD document to a react-admin compatible document.
 *
 * @param {Object} document
 * @param {bool} clone
 *
 * @return {ReactAdminDocument}
 */
export const transformJsonLdDocumentToReactAdminDocument = (
  document,
  clone = true,
  addToCache = true,
) => {
  if (clone) {
    // deep clone documents
    document = JSON.parse(JSON.stringify(document));
  }

  // The main document is a JSON-LD document, convert it and store it in the cache
  if (document['@id'] && document['@id'].indexOf('/') > -1) {
    document = new ReactAdminDocument(document);
  }
  else if(document['id']){
    document = new IdBasedReactAdminDocument(document);
  }
  else if(document['code']){
    document = new SyliusReactAdminDocument(document);
  }

  // Replace embedded objects by their IRIs, and store the object itself in the cache to reuse without issuing new HTTP requests.
  Object.keys(document).forEach(key => {
    // to-one
    if (isPlainObject(document[key]) && document[key]['@id'] && document[key]['@id'].indexOf('/') > -1) {
      if (addToCache) {
        reactAdminDocumentsCache[
          document[key]['@id']
        ] = transformJsonLdDocumentToReactAdminDocument(
          document[key],
          false,
          false,
        );
        document[key] = reactAdminDocumentsCache[document[key]['@id']];
      }
      // document[key] = document[key]['@id'];

      return;
    }

    // to-many
    if (
      Array.isArray(document[key]) &&
      document[key].length &&
      isPlainObject(document[key][0]) &&
      document[key][0]['@id'] && document[key][0]['@id'].indexOf('/') > -1
    ) {
      document[key] = document[key].map(obj => {
        if (addToCache) {
          reactAdminDocumentsCache[
            obj['@id']
          ] = transformJsonLdDocumentToReactAdminDocument(obj, false, false);
          return  reactAdminDocumentsCache[obj['@id']];
        }
        // return obj['@id'];
        return obj;
      });
    }
  });

  return document;
};

/**
 * Maps react-admin queries to a Hydra powered REST API
 *
 * @see http://www.hydra-cg.com/
 *
 * @example
 * CREATE   => POST http://my.api.url/posts/123
 * DELETE   => DELETE http://my.api.url/posts/123
 * GET_LIST => GET http://my.api.url/posts
 * GET_MANY => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * GET_ONE  => GET http://my.api.url/posts/123
 * UPDATE   => PUT http://my.api.url/posts/123
 */
export default (
  entrypoint,
  httpClient,
  apiDocumentationParser = parseHydraDocumentation,
) => {
  let apiSchema;

  /**
   * @param {Object} resource
   * @param {Object} data
   *
   * @returns {Promise}
   */
  const convertReactAdminDataToHydraData = (resource, data = {}) => {
    const fieldData = [];
    resource.writableFields.forEach(({name, reference, normalizeData}) => {
      if (!(name in data)) {
        return;
      }

      if (reference && data[name] === '') {
        data[name] = null;
        return;
      }

      if (undefined === normalizeData) {
        fieldData[name] = data[name];
        return;
      }

      fieldData[name] = normalizeData(data[name]);
    });

    const fieldDataKeys = Object.keys(fieldData);
    const fieldDataValues = Object.values(fieldData);

    return Promise.all(fieldDataValues).then(fieldData => {
      const object = {};
      for (let i = 0; i < fieldDataKeys.length; i++) {
        object[fieldDataKeys[i]] = fieldData[i];
      }

      return { ...object};
    });
  };

  /**
   * @param {Object} resource
   * @param {Object} data
   *
   * @returns {Promise}
   */
  const transformReactAdminDataToRequestBody = (resource, data = {}) => {

    if(!apiSchema || !apiSchema.resources) return Promise.resolve(data);

    resource = gomcoResource(resource);

    resource = apiSchema.resources.find(({name}) => resource === name);
    if (undefined === resource) {
      return Promise.resolve(data);
    }

    return convertReactAdminDataToHydraData(resource, data).then(data =>
      data,
    );
  };


  /**
   * @param {string} resource
   * @param {Object} data
   *
   * @returns {Promise}
   */
  const convertHydraDataToReactAdminData = (resource, data = {}) => {
    if(!apiSchema || !apiSchema.resources) return Promise.resolve(data);

    resource = apiSchema.resources.find(({name}) => resource === name);
    if (undefined === resource) {
      return Promise.resolve(data);
    }

    const fieldData = {};
    resource.fields.forEach(({name, denormalizeData}) => {
      if (!(name in data) || undefined === denormalizeData) {
        return;
      }

      fieldData[name] = denormalizeData(data[name]);
    });

    const fieldDataKeys = Object.keys(fieldData);
    const fieldDataValues = Object.values(fieldData);

    return Promise.all(fieldDataValues).then(fieldData => {
      const object = {};
      for (let i = 0; i < fieldDataKeys.length; i++) {
        object[fieldDataKeys[i]] = fieldData[i];
      }

      return {...data, ...object};
    });
  };

  const calculateTotal = (url, minimum = null) => {
    if(!url) return null;
    var total;
    var perPage = new URLSearchParams(url).get("perPage");
    var page = new URLSearchParams(url).get("page");
    total = perPage * Math.max(page,1);
    if(minimum !== null && page == 1 && minimum < total) return minimum;
    return  total;
  };

  /**
   * @param {Object} response
   * @param {string} resource
   * @param {string} type
   * @param {Object} request
   *
   * @returns {Promise}
   */
  const convertHydraResponseToReactAdminResponse = (
    type,
    resource,
    response,
    request
  ) => {

    if(request.filter && request.filter.exportfile){
      return Promise.resolve(transformJsonLdDocumentToReactAdminDocument(response.data))
          .then(data => convertHydraDataToReactAdminData(resource, data)).then(data => ({
            data: [data], total:  100
          }));

    }

    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        let items;
        items = response.data.items;
        if(!items) items = response.data['hydra:member'];
        if(!items) items = response.data;
        // TODO: support other prefixes than "hydra:"
        return Promise.resolve(
            items.map(
            transformJsonLdDocumentToReactAdminDocument,
          ),
        )
          .then(data =>
            Promise.all(
              data.map(data =>
                convertHydraDataToReactAdminData(resource, data),
              ),
            ),
          )
          .then(data => {
                var total = response.data['hydra:totalItems'];
                var view = response.data['hydra:view'];
                if(!total && view){
                  total = data.length;
                  var next = view['hydra:next'];
                  var current = view['@id'];
                  if(next) {
                    total = calculateTotal(next);
                  }
                  else if(current){
                    total = calculateTotal(current, total);
                  }
                }
                else if(response.data['totalItems']){
                  total = response.data['totalItems'];
                }
                else if(!total && !view){
                   total = data.length;
                }
                if(resource === 'searchs') {
                    const finalData = {data};
                    const finalTotal = {total};
                    if(response.data.buckets){
                      const facets = {};
                      facets.buckets = response.data.buckets;
                      facets.taxons = response.data.taxons;
                      facets.pricerange = [response.data.price_min, response.data.price_max];
                      finalTotal.facets = facets;
                    }
                    // finalTotal.taxons = response.data.taxons;
                    finalData.total = finalTotal;
                    return finalData;
                }
                else return {data, total};
          }
          );

      case DELETE && Object.entries(response.data).length === 0 && response.data.constructor === Object:
        return Promise.resolve({data: {id: null}});

      default:
        return Promise.resolve(
          transformJsonLdDocumentToReactAdminDocument(response.data),
        )
          .then(data => convertHydraDataToReactAdminData(resource, data))
          .then(data => ({data}));
    }
  };

  const getFieldsAsObject = array => array.reduce((a,b)=> (a[b.name]=b, a),{});

  const addAdditionPropertiesToSchema= (data) => {
    var apiSchemaNew = {};
    if(data){
      data.resources.forEach(function(resourceSchema, index, array) {
        var resourceMapping = {operations: getFieldsAsObject(resourceSchema.operations)};
        resourceMapping.fields = getFieldsAsObject(resourceSchema.fields);
        resourceMapping.readableFields = getFieldsAsObject(resourceSchema.readableFields);
        resourceMapping.writableFields = getFieldsAsObject(resourceSchema.writableFields);
        apiSchemaNew[resourceSchema.name] = {...resourceMapping};
        // apiSchema[resourceSchema.name].resourceMapping = resourceMapping;
        apiSchemaNew[resourceSchema.name].resourceSchema = resourceSchema;
      });
    }
    return apiSchemaNew;
  };


  const getEntryPointUrl = () => {
      return new URL(entrypoint, process.env.REACT_APP_API_ENTRY_POINT);
  };

  const getItemUrl = (resource, params) => {

    return params.url
        ? new URL(params.url)
        : params.id.indexOf('/') > -1 ?  new URL(`${params.id}`, getEntryPointUrl())
        :new URL(`${process.env.REACT_APP_API_BASE + resource + '/'  + params.id}`, getEntryPointUrl());
  };

  const getCollectionUrl = (resource, params) => {
    return params && params.url ? new URL(params.url) : new URL(`${entrypoint}/${resource}`, getEntryPointUrl());
  };

  const dataProvider = {

    getIntrospect:  () => {
      if (apiSchema) return Promise.resolve({data: apiSchema});
      return apiDocumentationParser(entrypoint).then(({api}) => {
        api.apiSchema = addAdditionPropertiesToSchema(api);
        apiSchema = api;
        return {data: apiSchema};
      });
    },

    getMany:  (resource, params) => {
      return Promise.all(
          params.ids.map(id =>
              reactAdminDocumentsCache[id]
                  ? Promise.resolve({data: reactAdminDocumentsCache[id]})
                  : dataProvider.getOne(resource, {id}),
          ),
      ).then(responses => ({data: responses.map(({data}) => data)}));
    },

    deleteMany: (resource, params) => {
      return Promise.all(
          params.ids.map(id => this.delete(resource, {id})),
      ).then(responses => ({data: []}));
    },


    create: (resource, params) => {
      if(resource === 'feeds') return this.createFeed(resource, params);
      return transformReactAdminDataToRequestBody(resource, params.data).then(
          data => ({
            options: {
              data,
              method: 'POST',
            },
            url: getCollectionUrl(resource,params),
          }),
      ).then(httpOptions => fetchApi({...httpOptions, resource, params, type: CREATE}));
    },

    delete: (resource, params) => {
      return Promise.resolve({
        options: {
          method: 'DELETE',
        },
        url: getItemUrl(resource, params),
      }).then(httpOptions => fetchApi({...httpOptions, resource, params, type: DELETE}));

    },

    getList: (resource, params) => {
        return dataProvider.getFilterResult(resource, params);
    },

    getManyReference: (resource, params) => {
      return dataProvider.getFilterResult(resource, params, GET_MANY_REFERENCE);
    },

    getFilterResult:  (resource, params, type = GET_LIST) => {
      const collectionUrl = getCollectionUrl(resource,params);
      const {
        pagination: {page, perPage},
        sort: {field, order},
      } = params;

      if (order) collectionUrl.searchParams.set(`order[${field}]`, order);
      if (page) collectionUrl.searchParams.set('page', page);
      if (perPage) collectionUrl.searchParams.set('perPage', perPage);
      if (params.filter) {
        const buildFilterParams = (key, nestedFilter, rootKey) => {
          const filterValue = nestedFilter[key];

          var finalKey;
          finalKey = rootKey.replace('__', '.');
          var keyArray = finalKey.split("_");
          if(keyArray[1]) finalKey = `${keyArray[0]}[${keyArray[1]}]`;
          // else finalKey = key;

          if(keyArray[2]) finalKey = `${finalKey}[${keyArray[2]}]`;


          if (Array.isArray(filterValue)) {
            filterValue.forEach((arrayFilterValue, index) => {
              collectionUrl.searchParams.set(
                  `${finalKey}[${index}]`,
                  arrayFilterValue,
              );
            });
            return;
          }

          if (!isPlainObject(filterValue)) {
            collectionUrl.searchParams.set(finalKey, filterValue);
            return;
          }

          Object.keys(filterValue).forEach(subKey => {
            buildFilterParams(subKey, filterValue, `${finalKey}.${subKey}`);
          });
        };

        Object.keys(params.filter).forEach(key => {
          buildFilterParams(key, params.filter, key);
        });
      }

      if (type === GET_MANY_REFERENCE && params.target) {
        collectionUrl.searchParams.set(params.target, params.id);
      }

      return Promise.resolve({
        options: {},
        url: collectionUrl,
      }).then(httpOptions => fetchApi({...httpOptions, resource, params, type}));
    },

    getOne: (resource, params) => {
      return Promise.resolve({
        options: {},
        url: getItemUrl(resource, params),
      }).then(httpOptions => fetchApi({...httpOptions, resource, params, type: GET_ONE}));
    },


    update: (resource, params, url = null) => {
      let params_to_patch = {};
      if(params.previousData){
        params_to_patch = DifferenceBetweenTwoObjects(params.data, params.previousData);
        // console.log(params_to_patch);
        // for (let [key, value] of Object.entries(params.data)) {
        //   // if (key === 'version') continue;
        //   // if (key === 'updated_at') continue;
        //
        //   if (!isEqual(params.previousData[key],value)) { //only works for primitives
        //     params_to_patch[key] = value;
        //   }
        // }
      }
      else params_to_patch = params.data;

      return transformReactAdminDataToRequestBody(resource, params_to_patch).then(
          data => ({
            options: {
              data,
              method: 'PUT',
            },
            url: getItemUrl(resource,params),
          }),
      ).then(httpOptions => fetchApi({...httpOptions, resource, params, type: UPDATE}));
    },


    createFeed: (resource, params) => {
      var FormDataBody = new FormData();
      var data = params.data;
      // console.log(data);
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          var value = data[key];
          if(Array.isArray(value)){
            var i = 0;
            value.forEach(function(element) {
              var newkey = key + '[' + i + ']';
              FormDataBody.append(newkey, element);
              i++;
            });
          }
          else if(key !== 'feedFile') FormDataBody.append(key, data[key]);
        }
      }
      FormDataBody.append('feedFile', params.data.feedFile.rawFile);

      return  Promise.resolve({
        options: {
          data: FormDataBody,
          method: 'POST'
        },
        url: getCollectionUrl(resource,params)
      }).then(httpOptions => fetchApi({...httpOptions, resource, params, type: CREATE}));
    },

    changePassword: (resource, params) => {
      return Promise.resolve({
        options: {
          data: params.data,
          method: 'POST'
        },
        url: getItemUrl(resource, params),
      }).then(httpOptions => fetchApi({...httpOptions, resource, params, type: UPDATE}));
    },

    query:  (query) => {
      const { type, resource, payload } = query;
      return dataProvider[type](resource, payload);
    },

    getPromise: (httpOptions) => {
      return Promise.resolve(httpOptions).then(httpOptions => fetchApi(httpOptions));
    }
  };

  const fetchApi = ({url, options, resource, params, type}) => {
    return httpClient(url.href, options)
        .then( response => convertHydraResponseToReactAdminResponse(type, resource, response, params))
        .catch(error => {
          // console.log(error.response);
          return Promise.reject(error.response);
          // return response.response.json().then(Promise.reject.bind(Promise));
        });
  };
  return dataProvider;
};

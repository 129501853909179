export default config => ({filter}) => {
  // const headers = merge(
  //     channelParams(config),
  //     localeParams(config),
  //     authParams(config),
  // );
  return {
    query: () => {
      return {
        type: 'getList',
        resource: 'products',
        payload: {
          url: `${config.baseUrl}/searchupc`,
          pagination: {},
          sort: {},
          filter: filter
        },
      };
    },
    response: (response) => {
      return response;
    }
  };
};

import {exporter} from "dataProvider/Exporter";
import {ListActions} from "UI/Common/ListAction";
import React from "react";
import {BulkActionButtons} from "../Product/BulkAction";
import { Pagination } from 'react-admin';

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;


export const DefaultListProps = ({operations}) => {
    return {
        exporter: exporter,
        actions: <ListActions/>,
        pagination: <PostPagination/>,
        bulkActionButtons: <BulkActionButtons operations={operations}/>,
    };
};

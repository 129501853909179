import React from 'react';
import './App.css';
import {dataProvider} from "./dataProvider/fetchHeader";
// import {apiDocumentationParser} from "./dataProvider/HydraDocumentationParser";
import {authProvider} from "./security/authProvider";
import {MyLayout} from "./theme/MyLayout";
import {customRoutes} from "./route/customRoutes";
import {
    AdminContext,ExporterContext
} from 'react-admin';
import { createMuiTheme } from '@material-ui/core/styles';
import {NotFound} from "UI/Common/NotFound";
import {CoreUI} from "resources";
import {IntrospectReducer} from "./Reducer/IntrospectReducer";
import {Login, Logout} from "ra-ui-materialui";
import {SchemaAnalyzerContext, hydraSchemaAnalyzer} from "@api-platform/admin";
import {Dashboard} from "./dashboard/Dashboard";
import {exporter} from "dataProvider/Exporter";

// const hydraSchemaAnalyzer = schemaAnalyzer();

const primaryMainColor = '#38a9b4';
const theme = createMuiTheme({
    spacing: 3,
    palette: {
        primary: {
            contrastText: '#ffffff',
            main: primaryMainColor
        },
        secondary: {
            main: '#288690'
        }

    },
    overrides:  {
        RaNotification: {
            appFrame: {
                marginTop: '0px!important',
            }
        },
        RaLayout: {
            appFrame: {
                marginTop: '0px!important',
            }
        },
        RaFormInput: {
            input: {
                width: '100%',
            }
        },
        MuiTableHead: {
            root:   {
                backgroundColor: '#ffffff'
            }
        },
        MuiTableCell:   {
            head:   {
                fontWeight: 'bold',
                color: primaryMainColor,
            }
        },
        MuiSnackbar: {
            root: {
                zIndex: 1000000
            }
        },
        Layout: {
            root: {
                appFrame:   {
                    marginTop:  '0px'
                }
            }

        }
    },

    MuiTableRow:    {
        root:   {
            height: '40px'
        },
        head:   {
            height: '40px'
        }
    },

});

export default props => (
    <SchemaAnalyzerContext.Provider value={hydraSchemaAnalyzer()}>
        <AdminContext
            // apiDocumentationParser={ apiDocumentationParser }
            dataProvider={ dataProvider }
            authProvider={ authProvider }
            customReducers={ { bitcoinRate: IntrospectReducer } }
            // entrypoint={ process.env.REACT_APP_API_ENTRY_POINT}

        >
            <ExporterContext.Provider value={exporter}>
                <CoreUI theme={theme}
                        customRoutes={customRoutes}
                        layout={MyLayout}
                        catchAll={NotFound}
                        title={'Ecom Retails'}
                        loginPage={Login}
                        logout={Logout}
                        dashboard={Dashboard}
                >
                </CoreUI>
            </ExporterContext.Provider>
        </AdminContext>
    </SchemaAnalyzerContext.Provider>
);

import {dataProvider} from "../dataProvider/fetchHeader";
import {syliusApiClient} from "../dataProvider/syliusApiClient";

export const authProvider = {
    login: params => {
        // const { email: username, password } = params;
        const requestClient= syliusApiClient().user.login(params);
        const res = dataProvider.query(requestClient.query());
        return res.then(res => {
            return requestClient.response(res.data);
            // Router.push('/');
        });
    },

    logout: params => {
        console.log('called login');
        syliusApiClient().Session.User.remove();
        window.localStorage.removeItem('role');
        // Router.push('/login');
        return Promise.resolve();
    },
    checkAuth: params => {
        return syliusApiClient().Session.User.token() ? Promise.resolve() : Promise.reject();
    },
    checkError: params => {
        const status = params.status || params.code;
        if (401 === status || 403 === status) {
            syliusApiClient().Session.User.remove();
            window.localStorage.removeItem('role');
            // window.location.reload();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: params => {
        var allowed = true;
        const role = localStorage.getItem('role');
        if(params === 'CAN_SCAN') allowed = role.split(',').includes("ROLE_TICKET_ALL");
        return allowed ? Promise.resolve(allowed) : Promise.reject();
    },
};

import {getFinalCartToken} from "../session";

export default config => () => {
    return {
        query: () => {
            const cartToken = getFinalCartToken(config);
            return cartToken ? {
                type: 'delete',
                resource: 'carts',
                payload: {
                    url: `${config.baseUrl}/carts/${cartToken}/coupon`,
                    id: 1
                },
            } : null;
        },
        response: (response) => {
            return response;
        }
    };
};

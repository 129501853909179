import {syliusApiClient} from "../../syliusApiClient";

export default config => ({tokenValue}) => {
  return {
    query: () => {
      const token = syliusApiClient().Session.User.token();
      return token ?  {
        type: 'getOne',
        resource: 'tickets',
        payload: {
          url: `${config.baseUrl}/tickets/${tokenValue}`,
          id: process.env.REACT_APP_API_BASE + 'tickets/' +  tokenValue,
        },
      } : null;
    },
    response: (response) => {
      return response;
    }
  };
};

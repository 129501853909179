import React from "react";
// import {exporter} from "dataProvider/Exporter";

import {
    ExportButton as BaseExportButton,
} from 'react-admin';

export  const ExportButton = ({disabled, resource, sort, filter, ...props }) => {
    const exportFilter = Object.assign({exportfile: 'csv'}, filter);
    return (
        <BaseExportButton
            disabled={disabled}
            resource={resource}
            sort={sort}
            filter={exportFilter}
            // exporter={exporter}
        />
    )
};

export default config => ({email}) => {
    return {
        query: () => {
            return  {
                type: 'create',
                resource: 'user',
                payload: {
                    url: `${config.baseUrl}/request-password-reset`,
                    data: {
                        email
                    }
                },
            };
        },
        response: (response) => {
            return response;
        }
    };
};

import React from 'react';
import { AppBar } from 'react-admin';
import MyUserMenu from './MyUserMenu';

const MyAppBar = props => {
    return (
        <AppBar {...props} userMenu={<MyUserMenu />} title='gomcoapp' position={'static'}>
            {/*<Toolbar>*/}
            {/*    <Typography variant="title" id="react-admin-title" />*/}
            {/*</Toolbar>*/}
        </AppBar>
    )
};

export default MyAppBar;

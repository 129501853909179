import createSyliusApiClient from './SyliusShopApiClient';
import {getTime} from "../util/time";

const syliusClientCache = new Map();

const createCachedSSRSyliusApiClient = (props) => {
    const syliusClient = createSyliusApiClient({
        baseUrl: process.env.REACT_APP_API_ENTRY_POINT,
        channel: 'default',
        // cookieDomain: 'localhost',
        locale: 'en_US',
        cartCookieExpiration: getTime(1500),
        userCookieExpiration: 300000,
        cartTokenCookie: 'cartToken',
        ...props
    });
    syliusClientCache.set('ssr', syliusClient);
    return syliusClient;
};

export const syliusApiClient = (props) => syliusClientCache.get('ssr') || createCachedSSRSyliusApiClient(props);

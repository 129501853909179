import React  from "react";

import { Show,
    TextField,
    TabbedShowLayout,
    Tab, ReferenceArrayField, Datagrid, SimpleShowLayout
} from 'react-admin';
import { withStyles, createStyles } from '@material-ui/core/styles';
// import {useResourceOptions} from "hooks";
import {Table, TableHead, TableBody,TableRow, TableCell} from "@material-ui/core";
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {amazonOrderItem} from "../../resources";
// import {InvoiceTab} from "../invoice/invoice";
import InvoiceTab from "./InvoiceTab";

const Title = ({ record }) => {
    return <span>{record ? `"${record.amazonOrderId}"` : ''}</span>;
};

const Styles = function (theme) {
    return createStyles({

    });
};

export const ShowPlain = (props) =>
{
    return (
        <Show title={<Title/>} {...props}>
            <SimpleShowLayout>
                <InvoiceTab/>
            </SimpleShowLayout>
        </Show>
    );
};


const InvoiceItemTable = (props) =>{
    // const { readableFields, operations } = useResourceOptions(props.resource);
    // const { record } = props;

    return (
        <ReferenceArrayField label="Items" reference={amazonOrderItem.name} source="orderItems" {...props}>
             <Datagrid>
                 <FieldGuesser source="sellerSKU" />
                 <FieldGuesser source="asin" />
                 <FieldGuesser source="title" />
                 <FieldGuesser source="quantityOrdered" />
                 <FieldGuesser source="quantityShipped" />
                 <FieldGuesser source="itemPriceAmount" />
             </Datagrid>
         </ReferenceArrayField>

    )};

const InvoiceOrderDetails = (props) =>{
    // const { readableFields, operations } = useResourceOptions(props.resource);
    const { record } = props;

    const orderInfos = [
        'vatInvoiceNumber',
        'invoiceDate',
        'orderStatus',
        'fulfillmentChannel',
        'amazonOrderId',
        'sellerOrderId',
        'purchaseDate',
        'marketplaceId',
        'buyerName',
        'buyerEmail',
        'billingAddressCountryCode',
        'orderTotalCurrencyCode',
        'warehouseCenterId',
        'shippingPrice',
        'vatNumber',
        'Rate VAT',
        'Total VAT',
        'Shipping Tax',
        'GW Price',
        'GW Tax',
        'Item Tax',
        'ShippingDiscount',
        'PromoDiscount',
    ];

    return (
        <Table size="small" aria-label="Order Details">
            <TableHead>
                <TableRow>
                    <TableCell>Information</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    tableInfoColumn(orderInfos, record)
                }
                <TableRow key={'billingAddress'}>
                    <TableCell component="th" scope="row">{'billingAddress'}</TableCell>
                    <TableCell>{tableAddressInfoColumn(record)}</TableCell>
                </TableRow>
                <TableRow key={'shippingAddress'}>
                    <TableCell component="th" scope="row">{'shippingAddress'}</TableCell>
                    <TableCell>{tableAddressInfoColumn(record, 'shipping')}</TableCell>
                </TableRow>
                <TableRow key={'buyerTaxInfo'}>
                    <TableCell component="th" scope="row">{'buyerTaxInfo'}</TableCell>
                    <TableCell>{buyerTaxInfo(record)}</TableCell>
                </TableRow>
            </TableBody>
        </Table>

    )};


const tableInfoColumn = (orderInfos, record) => {

    return orderInfos.map(columnKey => {
            return (
                <TableRow key={columnKey}>
                    <TableCell component="th" scope="row">{columnKey}</TableCell>
                    <TableCell>{record[columnKey]}</TableCell>
                </TableRow>
            )
        })
};

const tableAddressInfoColumn = (record, prefix =  'billing') => {

    const addressColumns = [
        'AddressName',
        'AddressAddressLine1',
        'AddressAddressLine2',
        'AddressAddressLine3',
        'AddressCity',
        'AddressStateOrRegion',
        'AddressCountryCode',
        'AddressPostalCode',
        'AddressPhone',
    ];
    return addressColumns.map(columnKey => {
        var finalKey= prefix + columnKey;
        return (
            <TableRow key={finalKey}>
                <TableCell component="th" scope="row">{columnKey}</TableCell>
                <TableCell>{record[finalKey]}</TableCell>
            </TableRow>
        )
    })
};

const buyerTaxInfo = (record) => {

    const addressColumns = [
        'buyerVatNumber',
        'taxingRegion',
        'taxingClassification',
    ];
    return addressColumns.map(columnKey => {
        var finalKey= columnKey;
        return (
            <TableRow key={finalKey}>
                <TableCell component="th" scope="row">{columnKey}</TableCell>
                <TableCell>{record[finalKey]}</TableCell>
            </TableRow>
        )
    })
};

export const ShowFinal = withStyles(Styles)(ShowPlain);

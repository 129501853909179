import React  from "react";

import {
    Filter,
    TextInput,
    DateTimeInput,
    DateInput,
} from 'react-admin';
// import {  DateTimeInput } from 'react-admin-date-inputs';


export const FilterFinal = (props) => (
    <Filter {...props}>
        <TextInput label="Invoice Number" source="vatInvoiceNumber"/>
        <TextInput label="Type" source="transactionType"/>
        <TextInput label="Order Number" source="amazonOrder__amazonOrderId"/>
        <TextInput label="Invoice Name" source="billingAddressName"/>
        <TextInput label="VAT Number" source="vatNumber"/>
        <TextInput label="Sales Channel" source="amazonOrder__salesChannel"/>
        {/*<DateInput label="Date >=" parse source="purchaseDate_gt"/>*/}
        {/*<DateInput label="Date <=" source="purchaseDate_lt"/>*/}
        {/*<TextInput label="ASIN" source="asin"/>*/}
    </Filter>
);

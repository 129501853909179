import amazonorder from "./amazonorder";
import amazonorderitem from "./amazonorderitem";
import invoice from "./invoice";
import taxreport from "./taxreport";

export const resourceUIMapping = [
    amazonorder,
    amazonorderitem,
    invoice,
    taxreport,
    // Product,
    // AmazonProduct,
    // RepricerProduct,
    // RepricerLog,
    // Store,
    // feeds,
    // amazonfeedreport,
    // amazonproductadvertisingapi,
    // mwskey,
    // dropshipsetting,
    // repricersetting,
    // amazoninboundshipment,
    // amazoninboundshipmentplanitem,
    // amazoninboundshipmenttransport,
];

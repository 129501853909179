export default config => ({code}) => {
  return {
    query: () => {
      return {
        type: 'getOne',
        resource: 'pages',
        payload: {
          url: `${config.baseUrl}/pages/${code}`,
          id: code
        },
      };
    },
    response: (response) => {
      return response;
    }
  };
};

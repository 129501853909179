import {getFinalCartToken} from "../session";

export default config => ({shippingAddress, differentBilling, billingAddress = {}}) => {
  return {
    query: () => {

      if(!differentBilling) billingAddress = {};

      const cartToken = getFinalCartToken(config);
      return cartToken ? {
        type: 'update',
        resource: 'carts',
        payload: {
          url: `${config.baseUrl}/checkout/${cartToken}/address`,
          data: {shippingAddress, billingAddress}
        },
      } : null;
    },
    response: (response) => {
      return response;
    }
  };
};

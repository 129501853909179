import React, { Fragment }   from "react";

import { List,
    Datagrid,
    ShowButton,
    ReferenceField,
} from 'react-admin';
import IntrospectQuery from 'dataProvider/IntrospectQuery';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import {useResourceOptions} from "hooks";
import {amazonOrder} from "../../resources";
import {DefaultListProps} from "../Common/DefaultListProps";

export const ListPlain = ({...props }) => {

    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    // console.log(readableFields);
    return (
        <Fragment>
            <List  filters={<FilterFinal/>} {...DefaultListProps({operations})}  {...props}>
                <Datagrid>

                    {readableFields.vatInvoiceNumber && <FieldGuesser label={'Invoice Number'} source={'vatInvoiceNumber'} sortable={false}/>}
                    {readableFields.transactionType && <FieldGuesser label={'Type'} source={'transactionType'}/>}

                    {/*{readableFields.store &&*/}
                    {/*<ReferenceField label="Store" source="amazonOrder" reference={amazonOrder.name} link={false}>*/}
                    {/*    <OrderFields/>*/}
                    {/*</ReferenceField>*/}
                    {/*}*/}
                    {<FieldGuesser label={'Order Number'} source={'amazonOrder.amazonOrderId'}/>}
                    {/*{<OrderField label={'orderStatus'} source={'orderStatus'}/>}*/}

                    {<FieldGuesser label={'Invoice Date'} sourceField={'purchaseDate'} source={'amazonOrder.purchaseDate'}/>}
                    {<FieldGuesser label={'Invoice Name'} source={'amazonOrder.buyerName'} sortable={false}/>}
                    {<FieldGuesser label={'Total Invoice'} source={'totalAmount'} sortable={false}/>}

                    {<FieldGuesser label={'Sales Channel'} source={'amazonOrder.salesChannel'} sortable={false}/>}

                    {<FieldGuesser label={'Fulfilment Method'} source={'amazonOrder.fulfillmentChannel'} sourceField={'fulfillmentChannel'}/>}
                    {readableFields.vatNumber && <FieldGuesser label={'VAT Number'} source={'vatNumber'} sortable={false}/>}

                    {readableFields.warehouseCenterId && <FieldGuesser label={'Warehouse Center ID'} source={'warehouseCenterId'} sortable={false}/>}

                    {/*{readableFields.estimatedBoxContentsFee && <FieldGuesser label={'Estimated Box Contents Fee'} source={'estimatedBoxContentsFee'}/>}*/}
                    {/*{readableFields.transportStatus && <FieldGuesser label={'Transport Status'} source={'transportStatus'}/>}*/}
                    {/*{readableFields.planCreatedOn && <FieldGuesser label={'Plan Created On'} source={'planCreatedOn'}/>}*/}
                    {operations.view && <ShowButton/>}
                </Datagrid>
            </List>
        </Fragment>
    );
};


const OrderField = ({label,sourceField,source, ...props}) => {
    return (
        <>
            <ReferenceField label={label} source="amazonOrder" reference={amazonOrder.name} link={false} {...props}>
                <FieldGuesser label={label} source={sourceField || source}/>
            </ReferenceField>
        </>
    );
};

const TotalAmount = ({ record: {amazonOrder} }) => {
    return <span>{amazonOrder && amazonOrder.orderTotalAmount && amazonOrder.orderTotalCurrencyCode ? `${amazonOrder.orderTotalAmount} ${amazonOrder.orderTotalCurrencyCode}` : ''}</span>;
};

export const ListFinal = props => (
    <IntrospectQuery component={ListPlain} {...props} />
);

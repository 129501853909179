import React, { useEffect} from "react";
import {
    CoreAdminUI, useAuthState
} from 'react-admin';
import {  useState } from 'react';
import {useResourceScheme} from "../dataProvider/IntrospectQuery";
import { useSelector } from 'react-redux'
import {ResourceWithPermissions} from "../security";
import {resourceUIMapping as resources} from "UI";

var _reactAdmin = require("react-admin");

export const fetchResources = data => {
    // console.log(data);
    var newknownResources = [];
    for (var resource of resources) {
        var resourcekey = resource.name;
        var resourceScheme = data[resourcekey];
        if(resourceScheme) newknownResources.push(React.createElement(ResourceWithPermissions,{resourceSchema:resourceScheme, key:resourcekey, ...resource}));
    }
    return newknownResources;
};

export const CoreUI = (props) => {
    const [resourcesother, setResources] = useState([]);
    const pathname = useSelector(function (state) {
        return state.router.location.pathname;
    });

    const { authenticated, loaded: authLoaded } = useAuthState(pathname);
    const { data, loading } = useResourceScheme(null, authenticated);

    useEffect(() => {
        if(data) {
            setResources(fetchResources(data));
        }
    }, [data]);

    if (loading) {
        return React.createElement(_reactAdmin.Loading, null);
    }
    return (
        <CoreAdminUI {...props}>
            {resourcesother}
        </CoreAdminUI>
    );
};

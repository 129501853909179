import {syliusApiClient} from "../../syliusApiClient";

export default config => ({number}) => {
  return {
    query: () => {
      const token = syliusApiClient().Session.User.token();
      return token ?  {
        type: 'getOne',
        resource: 'paymenttokens',
        payload: {
          url: `${config.baseUrl}/paymenttoken/${number}`,
          id: process.env.REACT_APP_API_BASE + 'paymenttokens/' +  number,
          // id: tokenValue,
        },
      } : null;
    },
    response: (response) => {
      return response;
    }
  };
};

import {syliusApiClient} from "../../syliusApiClient";

export default config => ({id}) => {
  return {
    query: () => {
      const token = syliusApiClient().Session.User.token();
      return token ? {
        type: 'delete',
        resource: 'address-books',
        payload: {
          id: id,
        },
      } : null;
    },
    response: (response) => {
      return response;
    }
  };
};

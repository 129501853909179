import {syliusApiClient} from "../../syliusApiClient";

export default config => ({data}) => {
    return {
        query: () => {
            const finalData = {
                currentPassword: data.currentPassword,
                newPassword: {
                    first: data.password,
                    second: data.password,
                }
            };

            const token = syliusApiClient().Session.User.token();
            return token ? {
                type: 'update',
                resource: 'users',
                payload: {
                    url: `${config.baseUrl}/me/change-password`,
                    data: finalData
                },
            } : null;
        },
        response: (response) => {
            return response;
        }
    };
};

import {getFinalCartToken} from '../session';

export default config => () => {
    return {
        query: () => {
            const cartToken = getFinalCartToken(config);
            return cartToken ? {
                    type: 'getOne',
                    resource: 'carts',
                    payload: {
                        url: `${config.baseUrl}/checkout/${cartToken}/payment`,
                        id: cartToken
                    },
                }
                : null;
        },
        response: (response) => {
            if (response.payments[0].length === 0) {
                return [];
            }
            const paymentMethods = response.payments[0].methods;
            return Object.keys(paymentMethods).map(key => (paymentMethods[key]));
        }
    };
};

export default config => ({url, card}) => {
    // var card = {
    //     "holder" : "anything",
    //     "number" : "2223000010309111",
    //     "securityCode" : "123",
    //     "expireAt" : {
    //         "month" : "1",
    //         "day" : "1",
    //         "year" : "2023"
    //     }
    // };
    return {
        query: () => {
            const cartToken = true;
            return cartToken ? {
                type: 'create',
                resource: 'payment',
                payload: {
                    url: url,
                    data: card
                },
            } : null;
        },
        response: (response) => {
            return response;
        }
    };
};

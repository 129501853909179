import session from "../session";

export default config => ({email, firstName, lastName, password, ...rest}) => {
  // const plainPassword = {first: password, second:   password};
  const plainPassword = password;
  return {
    query: () => {
      return  {
        type: 'create',
        resource: 'user',
        payload: {
          url: `${config.baseUrl}/register`,
          data: {
            email,
            firstName,
            lastName,
            plainPassword,
            channel: config.channel,
            ...rest
          }
        },
      };
    },
    response: (response) => {
      session(config).User.set(response.data.tokenValue);
      return response;
    }
  };
};

export default config => ({id}) => {
  // const headers = merge(
  //     channelParams(config),
  //     localeParams(config),
  //     authParams(config),
  // );
  return {
    query: () => {
      return {
        type: 'getOne',
        resource: 'products',
        payload: {
          url: `${config.baseUrl}/products/${id}`,
          id: process.env.REACT_APP_API_BASE + 'products/' +  id,
        },
      };
    },
    response: (response) => {
      return response;
    }
  };
};

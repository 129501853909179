export default config => ({password, token}) => {
    return {
        query: () => {
            const data = {
                password: {
                    first: password,
                    second: password,
                }
            };

            return  {
                type: 'update',
                resource: 'user',
                payload: {
                    url: `${config.baseUrl}/password-reset/${token}`,
                    data: data
                },
            };
        },
        response: (response) => {
            return response;
        }
    };
};

// import fetchJsonLd from '@api-platform/api-doc-parser/lib/hydra/fetchJsonLd';
// import {getDocumentationUrlFromHeaders} from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
// import {promises} from 'jsonld';
import axios from 'axios';
import {fetchHeaders} from './fetchHeader';

/**
 * Sends HTTP requests to a Hydra API.
 *
 * Adapted from react-admin
 *
 * @copyright Kévin Dunglas
 *
 * @param {string} url
 * @param {object} options
 * @return {object}
 */
export default (url, options = {}) => {
  const headers = fetchHeaders();

  // const requestHeaders = options.headers || new Headers();
  //
  // if (options.user && options.user.authenticated && options.user.token) {
  //   requestHeaders.set('Authorization', options.user.token);
  // }

  // if(options.body) options.data = options.body;

  const finalConfig = {url, headers: headers, ...options};
  return axios.request(finalConfig).then(response => {
    // const status = response.status;

    // if (status < 200 || status >= 300) {
    //   return promises
    //     .expand(response.data, {
    //       base: getDocumentationUrlFromHeaders(response.headers),
    //     })
    //     .then(json => {
    //       return Promise.reject(
    //         new Error(
    //           json[0]['http://www.w3.org/ns/hydra/core#description'][0][
    //             '@value'
    //           ],
    //           status,
    //         ),
    //       );
    //     })
    //     .catch(e => {
    //       if (e.hasOwnProperty('body')) {
    //         return Promise.reject(e);
    //       }
    //
    //       return Promise.reject(
    //         new Error(response.statusText, status),
    //       );
    //     });
    // }

    return response;
  })
};

import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {roundValue} from "../Common/Currency";

export const Invoice = props => {
    const {record} = props;
    const order = record.amazonOrder;
    return (
        <>
            <InvoiceTab order={order} record={record}/>

        </>
    );

};

export const InvoiceTab = ({order, record}) => {
    return (
        <>

            <Grid container spacing={2}>
                <Address record={record}/>
                <Address prefix={'shipping'} record={order}/>
                <Grid item xs={6} sm={6} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant={"body1"} >
                                <b>Warehouse Center Id:</b> {record.warehouseCenterId}
                            </Typography>
                            <Typography variant={"body1"} >
                                <b> VAT Number:</b> {record.vatNumber}
                            </Typography>
                            <Typography variant={"body1"} >
                                <b>Sales Channel:</b> {order.salesChannel}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant={"body1"} >
                                <b>Order Number:</b> {order.amazonOrderId}
                            </Typography>
                            <Typography variant={"body1"} >
                                <b> Order Date:</b> {order.purchaseDate}
                            </Typography>
                            <Typography variant={"body1"} >
                                <b> Invoice Number:</b> {record.vatInvoiceNumber}
                            </Typography>
                            <Typography variant={"body1"} >
                                <b> Invoice Type:</b> {record.transactionType}
                            </Typography>
                            <Typography variant={"body1"} >
                                <b> Invoice Date:</b> {record.invoiceDate}
                            </Typography>

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Card>
                        <CardContent>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Sl No</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Unit Price</TableCell>
                                            {/*<TableCell>Discount</TableCell>*/}
                                            <TableCell>Qty</TableCell>
                                            <TableCell>Net Amount</TableCell>
                                            <TableCell>Tax Rate</TableCell>
                                            {/*<TableCell>Tax Type</TableCell>*/}
                                            <TableCell>Tax Amount</TableCell>
                                            <TableCell>Total Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            record.invoiceItems.map((invoiceItem, index) => {
                                                return (
                                                    <ItemRow invoiceItem={invoiceItem} number={index+1} key={index}/>
                                                )
                                            })
                                        }
                                        <TotalAmount invoice={record}/>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </>
    );

};

const ItemRow = ({invoiceItem,number}) =>{
    var rowSpan = 1;
    if(invoiceItem.shippingPriceAmount !== 0) rowSpan++;
    if(invoiceItem.giftWrapPriceAmount !== 0) rowSpan++;
    if(invoiceItem.promotionDiscountAmount !== 0) rowSpan++;
    if(invoiceItem.shippingDiscountAmount !== 0) rowSpan++;
    if(invoiceItem.giftWrapDiscountAmount !== 0) rowSpan++;
    return (
        <>
            {
                invoiceItem.itemPriceAmount !== 0
                &&
                <TransactionTableRow
                    rowSpan={rowSpan}
                    number={number}
                    type={invoiceItem.orderItem.title}
                    invoiceItem={invoiceItem}
                    amount={'itemPriceAmount'}
                    tax={'itemTaxAmount'}
                    quantity={invoiceItem.orderItem.quantityOrdered} taxRate={'itemTaxRate'}
                />
            }

            {
                invoiceItem.promotionDiscountAmount !== 0
                &&
                <TransactionTableRow
                    type={'Item Discount'}
                    invoiceItem={invoiceItem}
                    amount={'promotionDiscountAmount'}
                    tax={'promotionDiscountTaxAmount'}
                    quantity={1} taxRate={'promotionDiscountTaxRate'}
                />
            }

            {
                invoiceItem.shippingPriceAmount !== 0
                &&
                <TransactionTableRow
                    type={'Shipping'}
                    invoiceItem={invoiceItem}
                    amount={'shippingPriceAmount'}
                    tax={'shippingTaxAmount'}
                    quantity={1} taxRate={'shippingTaxRate'}
                />
            }

            {
                invoiceItem.shippingDiscountAmount !== 0
                &&
                <TransactionTableRow
                    type={'Shipping Discount'}
                    invoiceItem={invoiceItem}
                    amount={'shippingDiscountAmount'}
                    tax={'shippingDiscountTaxAmount'}
                    quantity={1} taxRate={'shippingDiscountTaxRate'}
                />
            }

            {
                invoiceItem.giftWrapPriceAmount !== 0
                &&
                <TransactionTableRow
                    type={'Gift Wrap'}
                    invoiceItem={invoiceItem}
                     amount={'giftWrapPriceAmount'}
                     tax={'giftWrapTaxAmount'}
                     quantity={1} taxRate={'giftWrapTaxRate'}
                />
            }

            {
                invoiceItem.giftWrapDiscountAmount !== 0
                &&
                <TransactionTableRow
                    type={'GiftWrap Discount'}
                    invoiceItem={invoiceItem}
                    amount={'giftWrapDiscountAmount'}
                    tax={'giftWrapDiscountTaxAmount'}
                    quantity={1} taxRate={'giftWrapDiscountTaxRate'}
                />
            }
        </>
    );
};

const TransactionTableRow = ({type,rowSpan,number, invoiceItem, quantity = 1, amount, tax, taxRate}) => {
    return (
        <TableRow>
            {number &&
            <TableCell rowSpan={rowSpan}>
                {number}
            </TableCell>}

            <TableCell>{type}</TableCell>
            <TableCell>{roundValue(invoiceItem[amount]/quantity)}</TableCell>
            {/*<TableCell>-</TableCell>*/}
            <TableCell>{quantity}</TableCell>
            <TableCell>{invoiceItem[amount]}</TableCell>
            <TableCell>{invoiceItem[taxRate].toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2})}</TableCell>
            {/*<TableCell>??</TableCell>*/}
            <TableCell>{invoiceItem[tax]}</TableCell>
            <TableCell>{roundValue(invoiceItem[amount] + invoiceItem[tax])}</TableCell>
        </TableRow>
    );
};

const TotalAmount = ({invoice}) =>{
    return (
        <>
            <TableRow>
                <TableCell align={"right"} colSpan={5}>
                    <b>Total</b>
                </TableCell>
                <TableCell><b>{invoice.amazonOrder.orderTotalCurrencyCode}</b></TableCell>
                <TableCell><b>{roundValue(invoice.totalTax)}</b></TableCell>
                <TableCell><b>{roundValue(invoice.totalAmount)}</b></TableCell>

            </TableRow>


        </>
    );
};

const Address  = ({prefix = 'billing',record, ...props}) => {
    return (
        <Grid item xs={6} sm={6} md={6}>
            <Card>
                <CardContent>
                    <Typography variant={"body1"}><b>{prefix} Address</b></Typography>
                    <Typography variant={"body1"}>
                        {record[prefix + 'AddressName']}
                    </Typography>
                    <Typography variant={"body1"} gutterBottom>
                        {record[prefix + 'AddressAddressLine1']}<br />
                        {record[prefix + 'AddressAddressLine2']}<br />
                        {record[prefix + 'AddressAddressLine3']}<br />
                        {record[prefix + 'AddressCity']}, {record[prefix + 'AddressStateOrRegion']}<br />
                        {record[prefix + 'AddressCountryCode']} - {record[prefix + 'AddressPostalCode']}<br />
                        {record[prefix + 'AddressPhone']}
                    </Typography>
                    {
                        prefix === 'billing' &&
                        <Typography variant={"body1"} >
                            <b>Buyer VAT Number:</b> {record.buyerVatNumber}
                        </Typography>
                    }

                </CardContent>
            </Card>

        </Grid>
    )
};

import {syliusApiClient} from "../../syliusApiClient";

export default config => ({data}) => {
  return {
    query: () => {
      const token = syliusApiClient().Session.User.token();
      return token ? {
        type: 'update',
        resource: 'users',
        payload: {
          url: `${config.baseUrl}/me`,
          data: data
        },
      } : null;
    },
    response: (response) => {
      return response;
    }
  };
};

import session, {getFinalCartToken} from "../session";
import decodeJwt from "jwt-decode";

export default config => (param) => {
  return {
    query: () => {
      const cartToken = getFinalCartToken(config);
      return  {
        type: 'create',
        resource: 'user',
        payload: {
          url: `${config.baseUrl}/login_check`,
          data: {...param, token: cartToken ? cartToken : null}
        },
      };
    },
    response: (response) => {
      const decodedToken = decodeJwt(response.token);
      window.localStorage.setItem('role', decodedToken.roles);
      session(config).User.set(response.token, decodedToken.exp*1000);
      return decodedToken;
    }
  };
};

const downloadCSV = function (url, filename) {
    var fakeLink = document.createElement('a');
    fakeLink.style.display = 'none';
    document.body.appendChild(fakeLink);
    fakeLink.setAttribute('href', url);
    // fakeLink.setAttribute('target', '_blank');
    fakeLink.setAttribute('download', filename + ".csv");
    fakeLink.click();
};


export const exporter = posts => {
    console.log(posts);
    downloadCSV(process.env.REACT_APP_API_BASE_URL + posts[0].downloadUrl, 'posts'); // download as 'posts.csv` file
};

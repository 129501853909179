import { useQuery} from 'react-admin';

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactAdmin = require("react-admin");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

export const useResourceScheme = (resourcename = null, authenticated = true) => {
  const { data, loading, error, loaded } = useQuery({
      type: 'getIntrospect',
      resource: null,
      payload: {authenticated: authenticated }
  }, {action: 'SCHEMA_FETCH'});

  if(error) {
      return { data, loading, error, loaded };
  }
  if(data){
    if(resourcename) return  {data: data.apiSchema[resourcename], loading, error, loaded };
    return {data: data.apiSchema, loading, error, loaded };
  }
  return { data, loading, error, loaded };
};

const IntrospectQuery = ({ component: Component, includeDeprecated, ...props}) => {
    const { data, loading, error } = useResourceScheme(props.resource);

    if (loading) {
      return _react.default.createElement(_reactAdmin.TranslationProvider, null, _react.default.createElement(_reactAdmin.Loading, null));
    }

    if (error) {
      console.error(error);
      return _react.default.createElement("div", null, "Error while reading the API schema");
    }

    if (data == null) {
      return null;
    }

  const resourceData = data;

  if (!resourceData || !resourceData.fields) {
      console.error(`Resource ${props.resource} not present inside api description`);
      return _react.default.createElement("div", null, "Resource $", props.resource, " not present inside api description");
  }

  return _react.default.createElement(Component, _extends({
    // data: data,
    // resource: props.resource,
    resourceSchema: resourceData,
    // resourceMapping: resourceData,
    // fields: fields,
    // readableFields: getFieldsAsObject(readableFields),
    // writableFields: getFieldsAsObject(writableFields),
  }, props));

};

IntrospectQuery.defaultProps = {
  includeDeprecated: false
};
IntrospectQuery.propTypes = {
  component: _propTypes.default.elementType,
  resource: _propTypes.default.string,
  includeDeprecated: _propTypes.default.bool
};
var _default = IntrospectQuery;
export default _default;

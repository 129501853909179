import React, { Fragment }   from "react";
import { List,
    Datagrid
} from 'react-admin';
import {ListActions} from "UI/Common/ListAction";
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import {BulkActionButtons} from "./BulkAction";
import {useResourceOptions} from "../../hooks";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List actions={<ListActions/>}  filters={<FilterFinal/>} bulkActionButtons={<BulkActionButtons operations={operations} /> }  {...props}>
                <Datagrid>
                    {readableFields.country && <FieldGuesser label={'Country'} source={'country'}/>}
                    {readableFields.vatnumber && <FieldGuesser label={'VAT Number'} source={'vatnumber'}/>}
                    {readableFields.total && <FieldGuesser label={'Total'} source={'total'}/>}
                    {readableFields.item && <FieldGuesser label={'Item'} source={'item'}/>}
                    {readableFields.tax && <FieldGuesser label={'Tax'} source={'tax'}/>}

                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;

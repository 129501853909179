import {getFinalCartToken} from "../session";

export default config => ({orderItemId}) => {
  return {
    query: () => {
      const cartToken = getFinalCartToken(config);
      return cartToken ? {
        type: 'delete',
        resource: 'carts',
        payload: {
          url: `${config.baseUrl}/carts/${cartToken}/items/${orderItemId}`,
          id: orderItemId
        },
      } : null;
    },
    response: (response) => {
      return response;
    }
  };
};

import React from 'react';
import LabelIcon from '@material-ui/icons/Label';
import SettingsIcon from '@material-ui/icons/Settings';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import BugReportIcon from '@material-ui/icons/BugReport';
import {amazonShipmentPlan, amazonShipmentPlanItem, amazonShipmentPlanTransports, amazonOrder,
  invoice,
  taxReport
} from "../resources";

const options = [
  {
    resource: amazonOrder.name,
    key: amazonOrder.name,
    // leftIcon: CloudUploadIcon,
  },
  {
    resource: invoice.name,
    key: invoice.name,
    // leftIcon: CloudUploadIcon,
  },
  {
    resource: taxReport.name,
    key: taxReport.name,
    // leftIcon: CloudUploadIcon,
  },
  {
    key: 'products-menu',
    label: 'Products',
    leftIcon: <LabelIcon/>,
    children: [
      {
        resource: 'products',
      },
      {
        resource: 'repricerproducts',
      },
      {
        resource: 'amazonproducts',
      },
    ],
  },
  {
    key: 'amazon-shipment-menu',
    label: 'FBA Shipments',
    leftIcon: <LabelIcon/>,
    children: [
      {
        resource: amazonShipmentPlan.name,
      },
      {
        resource: amazonShipmentPlanItem.name,
      },
      {
        resource: amazonShipmentPlanTransports.name,
      },
    ],
  },
  {
    resource: 'feeds',
    key: 'feeds',
    leftIcon: CloudUploadIcon,
  },

  {
    key: 'setting-menu',
    label: 'Settings',
    leftIcon: <SettingsIcon/>,
    children: [
      {
        resource: 'dropshipsettings',
      },
      {
        resource: 'repricersettings',
      },
      {
        resource: 'amazonpaas',
      },
      {
        resource: 'amazonmwskeys',
      },

    ],
  },

  {
    key: 'log-menu',
    label: 'Logs',
    leftIcon: <BugReportIcon/>,
    children: [
      {
        resource: 'repricerlogs',
      },
      {
        resource: 'amazonfeedreports',
      },

    ],
  },
];
export default options;

import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import {fetchHeaders} from "./fetchHeader";

export const apiDocumentationParser = entrypoint =>
    parseHydraDocumentation(entrypoint, {
        headers: new Headers(fetchHeaders()),
    }).then(
        ({ api }) => {
            // var pendingorderr = api.resources.find(currentValue => {
            //     if(currentValue.name == 'orders') return currentValue;
            //     return  null;
            // });
            // pendingorderr.name = 'penidngorder';
            // api.resources.push(pendingorderr);
            // console.log(pendingorderr);
            // console.log(api);
            return { api };
        },
        result => {
            // const { api, status } = result;

            // if (status === 401) {
            //     console.log(status);
            //     return Promise.resolve({
            //         api,
            //         status,
            //         customRoutes: [
            //             <Route path="/" render={() => <Redirect to="/login" />} />,
            //         ],
            //     });
            // }
            return Promise.reject(result);
        }
    );

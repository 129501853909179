import baseFetchHydra from './fetchHydra';
import gomcoDataProvider from "./dataProvider";
import {apiDocumentationParser} from "./HydraDocumentationParser";
// import {convertLegacyDataProvider} from 'react-admin';
// import {isServer} from "../util/isServer";
import {syliusApiClient} from "../dataProvider/syliusApiClient";

export const fetchHeaders = () => {
    const token = syliusApiClient().Session.User.token();
    if(token) return {'Authorization': `Bearer ${token}`}
};

export const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    // headers: new Headers(fetchHeaders()),
});

const dataProviderPre = gomcoDataProvider(process.env.REACT_APP_API_ENTRY_POINT, fetchHydra, apiDocumentationParser);

// export const dataProvider = convertLegacyDataProvider(dataProviderPre);
export const dataProvider = dataProviderPre;

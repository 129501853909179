export default config => () => {
  return {
    query: () => {
      return {
        type: 'getOne',
        resource: 'channels',
        payload: {
          url: `${config.baseUrl}/currentchannel`,
          id: 'currentchannel'
        },
      };
    },
    response: (response) => {
      return response;
    }
  };
};

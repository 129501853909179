import React, { Component } from 'react';
import { connect } from 'react-redux';
import { crudGetOne, UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
class MyUserMenuView extends Component {
    componentDidMount() {
        // console.log(this.props.crudGetOne);
        // this.fetchProfile();
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        return false;
    }

    fetchProfile = () => {
        this.props.crudGetOne(
            // The resource
            'products',
            // The id of the resource item to fetch
            '/meinadventskalender/api/firstproduct',
            // The base path. Mainly used on failure to fetch the data
            '/firstproduct',
            // Wether to refresh the current view. I don't need it here
            false
        );
    };

    render() {
        const { crudGetOne, profile, ...props } = this.props;
        return (
            <UserMenu label={'me'} icon={<AccountCircleIcon/>} {...props}>
                <MenuItemLink
                    to="/firstproduct"
                    primaryText="My profile"
                    leftIcon={<SettingsIcon />}
                />
            </UserMenu>
        );
    }
}

const mapStateToProps = state => {
    // console.log(state);

    const resource = 'products';
    const id = '/meinadventskalender/api/firstproduct';
    const profileState = state.admin.resources[resource];
    return {
        profile: profileState ? profileState.data[id] : null
    };
};


const MyUserMenu = connect(
    mapStateToProps,
    { crudGetOne }
)(MyUserMenuView);
export default MyUserMenu;

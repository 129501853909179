import React  from "react";

import { Show,
    TextField,
    TabbedShowLayout,
    ReferenceField,
    Tab, ReferenceArrayField, Datagrid
} from 'react-admin';
import { withStyles, createStyles } from '@material-ui/core/styles';
// import {useResourceOptions} from "hooks";
import {Table, TableHead, TableBody,TableRow, TableCell} from "@material-ui/core";
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {amazonOrder, amazonOrderItem} from "../../resources";
import Typography from '@material-ui/core/Typography';
import {Invoice} from "./invoice";

const Title = ({ record }) => {
    return <span>{record ? `"${record.amazonOrderId}"` : ''}</span>;
};

const Styles = function (theme) {
    return createStyles({

    });
};

export const ShowPlain = (props) =>
{
    return (
        <Show title={<Title/>} {...props}>
            <TabbedShowLayout>
                <Tab label="invoice">
                    <Invoice/>
                    {/*<InvoiceItemTable {...props}/>*/}
                    {/*<InvoiceOrderDetails {...props}/>*/}
                </Tab>
                {/*<Tab label="summary" path="summary">*/}
                {/*    <TextField source="asin" />*/}
                {/*    <TextField source="sku" />*/}
                {/*</Tab>*/}

            </TabbedShowLayout>
        </Show>
    );
};

const InvoiceItemTable = (props) =>{
    // const { readableFields, operations } = useResourceOptions(props.resource);
    // const { record } = props;

    return (
        <ReferenceArrayField label="Items" reference={amazonOrderItem.name} source="orderItems" {...props}>
             <Datagrid>
                 <FieldGuesser label={'SKU'}  source="sellerSKU" />
                 <FieldGuesser source="asin" />
                 <FieldGuesser source="title" />
                 <FieldGuesser label={'ordered'} source="quantityOrdered" />
                 <FieldGuesser label={'shipped'}  source="quantityShipped" />
                 <FieldGuesser label={'item am'} source="itemPriceAmount" />
                 <FieldGuesser label={'item tx'} source="itemTaxAmount" />
                 <FieldGuesser label={'shipping am'} source="shippingPriceAmount" />
                 <FieldGuesser  label={'shipping tx'} source="shippingTaxAmount" />
                 <FieldGuesser label={'GW am'} source="giftWrapPriceAmount" />
                 <FieldGuesser label={'GW tx'} source="giftWrapTaxAmount" />
                 <FieldGuesser label={'Shippping Discount'} source="shippingDiscountAmount" />
                 <FieldGuesser label={'Promo Discount'} source="promotionDiscountAmount" />

                 {/*<PricePlusTax source="itemPriceAmount" price={'itemPriceAmount'} tax={'itemTaxAmount'} />*/}
                 {/*<PricePlusTax source="shippingPriceAmount" price={'shippingPriceAmount'} tax={'shippingTaxAmount'} />*/}
                 {/*<PricePlusTax source="shippingDiscountAmount" price={'shippingDiscountAmount'}/>*/}
                 {/*<PricePlusTax source="promotionDiscountAmount" price={'promotionDiscountAmount'}/>*/}
                 {/*<PricePlusTax source="giftWrapPriceAmount" price={'giftWrapPriceAmount'} tax={'giftWrapTaxAmount'} />*/}
             </Datagrid>
         </ReferenceArrayField>

    )};

const PricePlusTax = ({record, price, tax}) => {
    console.log(record);
    return (
        <Typography>{record[price]} + {record[tax]}</Typography>
    )
};

const InvoiceOrderDetails = (props) =>{
    // const { readableFields, operations } = useResourceOptions(props.resource);
    const { record } = props;

    const orderInfos = [
        'vatInvoiceNumber',
        'invoiceDate',
        'billingAddressCountryCode',
        'warehouseCenterId',
        'vatNumber',

        // 'shippingPrice',
        // 'Rate VAT',
        // 'Total VAT',
        // 'Shipping Tax',
        // 'GW Price',
        // 'GW Tax',
        // 'Item Tax',
        // 'ShippingDiscount',
        // 'PromoDiscount',
    ];

    return (
        <Table size="small" aria-label="Order Details">
            <TableHead>
                <TableRow>
                    <TableCell>Information</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    tableInfoColumn(orderInfos, record)
                }
                <TableRow key={'billingAddress'}>
                    <TableCell component="th" scope="row">{'billingAddress'}</TableCell>
                    <TableCell>{tableAddressInfoColumn(record)}</TableCell>
                </TableRow>

                <ReferenceField link={false} label="Items" reference={amazonOrder.name} source="amazonOrder" {...props}>
                    <TableOrderRelatedFields/>
                </ReferenceField>

                <TableRow key={'buyerTaxInfo'}>
                    <TableCell component="th" scope="row">{'buyerTaxInfo'}</TableCell>
                    <TableCell>{buyerTaxInfo(record)}</TableCell>
                </TableRow>
            </TableBody>
        </Table>

    )};

const TableOrderRelatedFields = ({record}) => {
    const orderInfos = [
        'orderStatus',
        'fulfillmentChannel',
        'amazonOrderId',
        'sellerOrderId',
        'purchaseDate',
        'marketplaceId',
        'buyerName',
        'buyerEmail',
        'orderTotalCurrencyCode'
        ];
    return(
            <>
            {
                tableInfoColumn(orderInfos, record)
            }
            <TableShippingAddress record={record}/>
            </>
    )
};

const TableShippingAddress = ({record}) => {
    return (
        <TableRow key={'shippingAddress'}>
            <TableCell component="th" scope="row">{'shippingAddress'}</TableCell>
            <TableCell>{tableAddressInfoColumn(record, 'shipping')}</TableCell>
        </TableRow>
    )
};

const tableInfoColumn = (orderInfos, record) => {

    return orderInfos.map(columnKey => {
            return (
                <TableRow key={columnKey}>
                    <TableCell component="th" scope="row">{columnKey}</TableCell>
                    <TableCell>{record[columnKey]}</TableCell>
                </TableRow>
            )
        })
};

const tableAddressInfoColumn = (record, prefix =  'billing') => {

    const addressColumns = [
        'AddressName',
        'AddressAddressLine1',
        'AddressAddressLine2',
        'AddressAddressLine3',
        'AddressCity',
        'AddressStateOrRegion',
        'AddressCountryCode',
        'AddressPostalCode',
        'AddressPhone',
    ];
    return addressColumns.map(columnKey => {
        var finalKey= prefix + columnKey;
        return (
            <TableRow key={finalKey}>
                <TableCell component="th" scope="row">{columnKey}</TableCell>
                <TableCell>{record[finalKey]}</TableCell>
            </TableRow>
        )
    })
};

const buyerTaxInfo = (record) => {

    const addressColumns = [
        'buyerVatNumber',
        'taxingRegion',
    ];
    return addressColumns.map(columnKey => {
        var finalKey= columnKey;
        return (
            <TableRow key={finalKey}>
                <TableCell component="th" scope="row">{columnKey}</TableCell>
                <TableCell>{record[finalKey]}</TableCell>
            </TableRow>
        )
    })
};

export const ShowFinal = withStyles(Styles)(ShowPlain);
